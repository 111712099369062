import {React, useState, useEffect }from 'react'
import './topbar.css'

export default function Topbar({storeid}) {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
  
  return (
    <div className='topbarWrapper'>
        <div className="topbarDetails">
            <div className="nameDetails">
                <img src="/assets/ab.png" alt="" className='yunshulogo' />
                <h4>Albatrox Logistics</h4>
            </div>
            <div className="accountDetails">
                <h5>Welcome, {storeid}</h5>
                <p>{formattedDate}</p>
            </div>
          
        </div>
        
    </div>
  )
}
